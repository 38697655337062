/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-debugger */

import React, { useEffect, useState, useMemo, useContext } from "react";
import {
    Grid,
    Typography,
    Card,
    CardContent,
    RACDatePicker,
    RACButton,
    RACSelect,
    RACTextbox,
    RACBadge,
    RACTableCell,
    List,
    ListItem,
    RACModalCard,
    RACTable,
    RACTableRow,
    Box,
    CircularProgress
} from "@rentacenter/racstrap";
import { reportGlobalStyles } from "./reportsGlobalStyles";
import { PBIReports } from "./PBIReports";
import { useHistory } from 'react-router-dom';
import { getEmbedInfo, getmenuandstore, storeConfigCall } from '../../api/user'
import { ContainerContext } from '../../app/App';
import { CustomPropInterface } from '../../index';
import Modal from 'react-modal';
enum tabList {
    REPORT_TYPES,
}
const WORKSPACE_ID = '9e2b5dd6-81cc-434e-b2af-b8d54519fe44';
function ReportDetails(props: any) {
    const classes: any = reportGlobalStyles();
    const [reportDetail, setReportDetail] = useState<any>([])
    const [coWorkerId, setCoWorkerId] = useState<any>([])
    const [tailCLicked, settailCLicked] = useState<any>(false)
    const [embedInfoResponse, setembedInfoResponse] = useState<any>('')


    const [activetab, setactivetab] = useState<tabList | undefined>(
        tabList.REPORT_TYPES
    );
    const containerData = useContext(ContainerContext) as CustomPropInterface;
    const existingReports: any = [
        {
            "displayValue": "Daily Activity Planner Event Report",
            "menuItemKey": "DailyActivityPlanner",
            "menuItemValue": "Daily Activity Planner Event Report",
            "name": "DailyActivityPlanner",
            url: "reports/daily-activity-planner-event"
        },
        {
            "displayValue": "Store Stats for the Day",
            "menuItemKey": "DailyReport",
            "menuItemValue": "Daily Report",
            "name": "DailyReport",
            url: 'reports/daily'
        },
        {
            "displayValue": "Exception Report",
            "menuItemKey": "ExceptionAuditTrial",
            "menuItemValue": "Exception Audit Trail Report",
            "name": "ExceptionAuditTrial",
            url: 'reports/exception-audit-trail'
        },
        {
            "displayValue": "In Store Inventory",
            "menuItemKey": "IdleInventoryReport",
            "menuItemValue": "Idle Inventory Report",
            "name": "IdleInventoryReport",
            url: 'reports/idle-inventory'
        },
        {
            "displayValue": "Inventory In",
            "menuItemKey": "InventoryInAuditTrialReport",
            "menuItemValue": "Inventory In Audit Trail Report",
            "name": "InventoryInAuditTrialReport",
            url: 'reports/inventory-in-audit-trail'
        },
        {
            "displayValue": "Inventory Out",
            "menuItemKey": "InventoryOutAuditTrialReport",
            "menuItemValue": "Inventory Out Audit Trail Report",
            "name": "InventoryOutAuditTrialReport",
            url: '/reports/inventory-out-audit-trail'
        },
        {
            "displayValue": "Inventory Status",
            "menuItemKey": "InventoryRecapReport",
            "menuItemValue": "Inventory Recap Report",
            "name": "InventoryRecapReport",
            url: 'reports/inventory-recap'
        },
        {
            "displayValue": "Items in Service",
            "menuItemKey": "ItemBeingServicedReport",
            "menuItemValue": "Items Being Serviced Report",
            "name": "ItemBeingServicedReport",
            url: 'reports/items-being-serviced'
        },
        {
            "displayValue": "Agreements and Customers on Rent",
            "menuItemKey": "OnRentInventoryReport",
            "menuItemValue": "On Rent Inventory Report",
            "name": "OnRentInventoryReport",
            url: 'reports/on-rent-inventory'
        },
        {
            "displayValue": "Petty Cash Detail",
            "menuItemKey": "PettyCashAnalysisDetailReport",
            "menuItemValue": "Petty Cash Analysis Detail Report",
            "name": "PettyCashAnalysisDetailReport",
            url: 'reports/petty-cash-detail'
        },
        {
            "displayValue": "Petty Cash Summary",
            "menuItemKey": "PettyCashAnalysisSummaryReport",
            "menuItemValue": "Petty Cash Analysis Summary Report",
            "name": "PettyCashAnalysisSummaryReport",
            url: 'reports/petty-cash-analysis-summary'

        },
        {
            "displayValue": "All Receipts for a Certain Time Frame",
            "menuItemKey": "ReceiptAuditTrailReport",
            "menuItemValue": "Receipt Audit Trail Report",
            "name": "ReceiptAuditTrailReport",
            url: 'reports/receipt-audit'
        },
        {
            "displayValue": "Sales Per Coworker",
            "menuItemKey": "SalesPersonRecapReport",
            "menuItemValue": "Sales Person Recap Report",
            "name": "SalesPersonRecapReport",
            url: 'reports/sales-person-recap'
        },
        {
            "displayValue": "Skip Stolen Report",
            "menuItemKey": "SkipStolenReport",
            "menuItemValue": "Skip Stolen Report",
            "name": "SkipStolenReport",
            url: 'reports/skip-stolen'
        },
        {
            "displayValue": "All Transactions Completed In a Certain Time Frame",
            "menuItemKey": "TransactionAuditTrialReport",
            "menuItemValue": "Transaction Audit Trail Report",
            "name": "TransactionAuditTrialReport",
            url: 'reports/transaction-audit'
        }
    ]
    const headers: any = ['OPR', 'TRA', 'INV', 'CASHMGMT']
    const history: any = useHistory();


    useEffect(() => {
        const pageLoad: any = async () => {
            // const payload: any = {
            //     "storeNumbers": [
            //         sessionStorage.getItem('storeNumber')
            //     ],
            //     "paramKeyNames": [
            //         "InventoryRecapReport",
            //         "InventoryInAuditTrialReport",
            //         "InventoryOutAuditTrialReport",
            //         "ItemBeingServicedReport",
            //         "OnRentInventoryReport",
            //         "PettyCashAnalysisDetailReport",
            //         "PettyCashAnalysisSummaryReport",
            //         "ReceiptAuditTrailReport",
            //         "SalesPersonRecapReport",
            //         "SkipStolenReport",
            //         "DailyActivityPlanner",
            //         "ExceptionAuditTrial",
            //         "IdleInventoryReport",
            //         "TransactionAuditTrialReport",
            //         "DailyReport"
            //     ]
            // }
            // setloader(true)
            // const apiCallStack = [storeConfigCall(payload)]
            // if (!containerData) {
            //     apiCallStack.push(getWorkerRole())
            // } else {
            //     setCoWorkerId(containerData?.GetEmployeeId());
            // }
            const mapData = new Map()

            props && props.reportDetails && props.reportDetails.storeProfileResponse?.configDetails[0]?.configDetails?.forEach((el: any) => {
                const grpName = getGroupName(el.paramKeyName)
                const currentData = existingReports.find((filterEl: any) => el.paramKeyName == filterEl.name)
                currentData.groupName = grpName
                if (el.paramValue !== '0') {
                    currentData.url = el.paramValue
                    currentData.src = 'EXTERNAL_REPORT'
                } else {
                    currentData.src = 'INTERNAL_REPORT'
                }
                if (!mapData.has(grpName)) {
                    mapData.set(grpName, [currentData])
                } else {
                    const data = mapData.get(grpName)
                    data.push(currentData)
                    mapData.set(grpName, data)
                }
            })
            const data = [...mapData.values()]
            setReportDetail(data)
            // console.log(getReportDetails)
        }
        pageLoad()
    }, [props?.reportDetails])
    const getWorkerRole = async () => {
        if (containerData !== undefined) {
            console.log('containerData', containerData);
            console.log('containerDataRole', containerData.GetEmployeeId());
            setCoWorkerId(containerData.GetEmployeeId());
        } else {
            const coWorkerRoleObj: any = await getmenuandstore();
            if (
                coWorkerRoleObj?.coworkerProfile !== undefined &&
                coWorkerRoleObj?.coworkerProfile !== null &&
                coWorkerRoleObj?.coworkerProfile.employeeId !== undefined &&
                coWorkerRoleObj?.coworkerProfile.employeeId !== null
            ) {
                debugger
                const coworkerId = coWorkerRoleObj?.coworkerProfile.employeeId;
                setCoWorkerId(coworkerId)
            }
        }
    };
    const getGroupName = (paramName: string) => {
        if (['DailyActivityPlanner', 'DailyReport', 'SalesPersonRecapReport', 'SkipStolenReport'].includes(paramName)) return 'OPR'
        if (['ExceptionAuditTrial', 'ReceiptAuditTrailReport', 'TransactionAuditTrialReport'].includes(paramName)) return 'TRA'
        if (['IdleInventoryReport', 'InventoryInAuditTrialReport', 'InventoryOutAuditTrialReport', 'InventoryRecapReport', 'ItemBeingServicedReport', 'OnRentInventoryReport'].includes(paramName)) return 'INV'
        if (['PettyCashAnalysisDetailReport', 'PettyCashAnalysisSummaryReport'].includes(paramName)) return 'CASHMGMT'
    }

    const redirectHandler = async (el: any) => {
        if (el.src == 'EXTERNAL_REPORT') {
            history?.push({ pathname: `/reporting/${el.name}` })
        } else {
            history?.push({ pathname: el.url })
        }
    }

    return (
        <Card className={`${classes.mt3} ${classes.mr2}`} style={{ width: '100%', marginTop: '50px',backgroundColor:'#ecf0f3' }}>
            <div
                // item
                className={`${classes.w100}`}>
                <Box>
                    <List
                        className={`${classes.py0}`}
                        id="CustTab"
                        role="tabList"
                    >
                        <ListItem
                            className={
                                activetab == 0
                                    ? `${classes.navLinkActiveHeader} ${classes.navLinkItem}`
                                    : `${classes.navLinkItem}`
                            }
                            style={{ paddingLeft: '25px' }}
                            id="Customerinfo-tab"
                            data-testid="customerInfoTab"
                            data-bs-toggle="tab"
                            data-bs-target="#customerinfo"
                            role="tab"
                            aria-controls="Customerinfo-tab"
                            aria-selected="true"
                            onClick={() => setactivetab(tabList.REPORT_TYPES)}
                        >
                            Report Types
                        </ListItem>

                    </List>
                </Box>
            </div>
            {reportDetail.length > 0 && reportDetail.map((repDetail: any,) => {
                return (<>
                    <Typography variant="h5" style={{ paddingLeft: '25px', paddingTop: '10px' }}>{repDetail[0]?.groupName == 'OPR' ? 'Operation' : repDetail[0]?.groupName == 'TRA' ? 'Transaction' : repDetail[0]?.groupName == 'INV' ? 'Inventory' : repDetail[0]?.groupName == 'CASHMGMT' ? 'Cash Management' : ''}</Typography>
                    <Grid style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                        {repDetail && repDetail.length > 0 && repDetail.map((el: any, index: any) => {
                            return (<Grid key={index} onClick={() => redirectHandler(el)} style={{ padding: '1rem', width: '22%', borderRadius: '5px', margin: '1%', fontFamily: 'Open Sans",sans-serif', boxShadow: '0 0.25rem 0.4375rem 0 rgba(19,37,46,.08)', cursor: 'pointer',backgroundColor:'white' }}>
                                <Grid style={{ paddingBottom: '10px' }}><Typography>{el.menuItemValue}</Typography></Grid>
                                <Grid><span>{el.displayValue}</span></Grid>
                            </Grid>)
                        })}
                    </Grid>
                </>)
            })}</Card>
    )
}

export default ReportDetails;
