/* eslint-disable prettier/prettier */
import { client, defaultClient } from "./client";
import { appConfig } from "../config/app-config";
import { CancelToken } from "axios";
import { ProductPermissions } from "../constants/constants";
import { User } from "../types/types";
import jwtDecode from "jwt-decode";

export const getCurrentUser = (): Promise<User> =>
  defaultClient(
    "users/current",
    { method: "GET" },
    appConfig.apiUrls.container,
    true
  );

export const getUserPreferences = (productType: string) =>
  client(
    `users/current/preferences/${productType}`,
    { method: "GET" },
    appConfig.apiUrls.container
  );

export const getUserPermissions = (
  productType: string
): Promise<ProductPermissions[]> =>
  client(
    `users/current/permissions/${productType}`,
    { method: "GET" },
    appConfig.apiUrls.container
  );

export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,
    {
      method: "PATCH",
      body: [payload],
      cancelToken,
    },
    appConfig.apiUrls.container
  );

export const getUserStores = () =>
  client(
    "users/current/stores?q=permissions[:includes]=RACPAD_DAP",
    { method: "GET" },
    appConfig.apiUrls.container
  );

export const getmenuandstore = async () => {
  try {
    const jwtToken = localStorage.getItem("jt") || "dd";
    const decodedToken: any = jwtDecode(jwtToken);
    // eslint-disable-next-line no-console
    console.log("decodedToken", decodedToken);
    const parameters = {
      coworkerId: decodedToken["custom:employeeId"],
    };
    return await client(
      "menu-and-stores",
      { method: "POST", body: parameters },
      appConfig.apiUrls.container
    );
  } catch (e: any) {
    return null;
  }
};
export const storeConfigCall = async (payload: any) => {
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log("data", payload);
  try {
    /* eslint-enable no-console */
    return await client(
      `store-details`,
      { method: "POST", body: payload },
      appConfig.apiUrls.storemgmt
    );
  } catch (e: any) {
    return null;
  }
};
export const getEmbedInfo = async (payload?: any) => {
  return await client(
    `report/generatetoken`,
    { method: "POST", body: payload },
    appConfig.apiUrls.micro
  );
};
export const exportReport = async (payload?: any) => {
  return await client(
    `report/export`,
    { method: "POST", body: payload },
    appConfig.apiUrls.micro
  );
};
