/* eslint-disable prettier/prettier */
/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */

import { makeStyles, RACCOLOR } from "@rentacenter/racstrap";
export const reportGlobalStyles = () => {
  const useClasses = makeStyles((theme) => ({
    fixedheadertableonlinePopup: {
      overflowY: "auto",
      maxHeight: theme.typography.pxToRem(450),
    },
    RACexpandIconclose: {
      width: theme.typography.pxToRem(22),
      height: theme.typography.pxToRem(22),
      right: theme.typography.pxToRem(4),
      top: theme.typography.pxToRem(5),
      position: "absolute",
      cursor: "pointer",
    },
    textcenterImage: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(20),
      width: "100%",
      fontFamily: "OpenSans-semibold",
      color: "#A5A6A6",
      fontSize: theme.typography.pxToRem(14),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    fixedheadertableonline: {
      overflowY: "auto",
      maxHeight: theme.typography.pxToRem(235),
    },
    cardForDashboard: {
      borderRadius: "16px!important",
      boxShadow: "0 1px 4px 0 rgba(0,0,0,0)!important",
      width: "98%",
    },
    h65: {
      heigh: "65px",
    },
    w90: {
      width: "90%",
    },
    customerRaclink: {
      color: `${RACCOLOR.BLUE_CRAYOLA}`,
      textDecoration: "none",
      fontFamily: "OpenSans-semibold",
      cursor: "pointer",
    },
    explanationContent: {
      justifyContent: "right",
    },
    spacerMT4: {
      marginTop: theme.typography.pxToRem(24),
    },
    spacerMR2: {
      marginRight: theme.typography.pxToRem(8),
    },
    foc: {
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "1px 1px 6px 3px #80bdff",
      },
    },
    cancelReason: {
      "&:focus": {
        color: "#212529",
        backgroundColor: "#fff",
        borderColor: "#86b7fe",
        outline: "0",
        boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 25%)",
      },
    },
    RACLoaderPage: {
      textAlign: "center",
      marginTop: "250px",
      fontSize: "16px",
      marginLeft: "30px",
      marginRight: "30px",
      outline: "none",
    },
    unassignBtnClass: {
      color: "#c00808",
      backgroundColor: "#FFD1D1",
      "&:hover": {
        color: "#c00808",
        backgroundColor: "#FFD1D1",
        outline: "none",
      },
    },
    headerTab: {
      paddingBottom: "0 !important",
    },
    ml24: {
      marginLeft: "-18px",
    },
    categoryDD: {
      width: "100px",
      marginLeft: "15px",
      paddingTop: "12px",
    },
    w100Px: {
      width: "110px",
    },
    todayButton: {
      height: "33px",
      marginTop: "12px",
    },
    filterContainerItems: {
      display: "flex",
      //   width: "25%",
      marginRight: "5%",
    },
    leftContainer: {
      width: "80%",
      height: "100%",
      display: "flex",
    },
    rightContainer: {
      width: "20%",
      height: "100%",
    },
    filterContainer: {
      width: "100%",
      height: "65px",
      display: "flex",
    },
    containerWid: { width: "100%", float: "left" },
    searchBarIcon: {
      border: "1px solid #D9E2ED",
      borderRight: "none",
      borderTopRightRadius: "unset",
      borderBottomRightRadius: "unset",
      minWidth: "32px !important",

      "&:hover": {
        borderColor: "#D9E2ED !important",
        boxShadow: "unset !important",
        backgroundColor: "unset",
      },
    },
    racstrapTablecellBgColor: {
      backgroundColor: `${RACCOLOR.WHITE}`,
      color: "grey",
    },
    racFixedFooterMargin: {
      marginBottom: "120px",
    },
    card: {
      border: "none",
      borderRadius: "1rem",
      boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
    },
    RACTextBox: {
      width: "50% !important",
      "&:focus": {
        color: "#212529",
        backgroundColor: "#fff",
        borderColor: "#86b7fe",
        outline: "0",
        boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 25%)",
      },
      "& input": {
        padding: "9px",
      },
    },
    storeEventNots: {
      width: "100%",
      display: "flex",
      "& input": {
        width: "100%",
      },
      "& div": {
        width: "100%",
      },
    },
    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    inputGroup: {
      position: "relative",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "strech",
      width: "100%",
    },
    searchBar: {
      justifyContent: "end",
      paddingTop: "14px",
      "&:focus": {
        boxShadow: "0 0 0 0.25rem rgb(0 123 255 / 25%) !important",
      },
    },
    searchSpacing: {
      "& input": {
        borderTopLeftRadius: "unset",
        borderBottomLeftRadius: "unset",
        borderLeft: "none",
        borderColor: "#D9E2ED !important",
        padding: "10px",
        "&:focus": {
          borderColor: "#D9E2ED !important",
          boxShadow: "unset !important",
          borderTopLeftRadius: "unset",
          borderBottomLeftRadius: "unset",
        },
      },
    },
    dropDown: {
      "& div": {
        "& div": {
          "& div": {
            border: "none",
          },
        },
      },
    },
    searchEventGrid: {
      float: "right",
      marginLeft: "34%",
    },
    SearchGrid: {
      float: "right",
      marginLeft: "53.5%",
    },
    searchStyle: {
      // '& div':{
      // color:"#212529",
      // },
      "& input": {
        borderTopLeftRadius: "unset",
        borderBottomLeftRadius: "unset",
        borderLeft: "none",
        borderColor: "#D9E2ED !important",
        padding: "10px",
        "&:focus": {
          borderColor: "#D9E2ED !important",
          boxShadow: "unset !important",
          borderTopLeftRadius: "unset",
          borderBottomLeftRadius: "unset",
        },
      },
    },
    customerToolbar: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `white`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    fixedBottom: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `white`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      padding: "1rem",
    },
    smartWizardListLinkActive: {
      textAlign: "center",
      position: "relative",
      marginRight: theme.typography.pxToRem(14),
      width: "auto",
      color: `${RACCOLOR.DEEP_SKY_BLUE}`,
      cursor: "pointer",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(12),
      textDecoration: "none",
    },
    borderDefault: {
      border: "2px solid #d9d9d9",
      padding: "4px 10px",
      borderRadius: "50px",
      margin: "0 5px",
      color: "#8E8E8E",
    },
    smartWizardListLinkInActive: {
      textAlign: "center",
      position: "relative",
      marginRight: theme.typography.pxToRem(14),
      width: "auto",
      cursor: "pointer",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(12),
      textDecoration: "none",
      color: "#9a9a9a",
    },
    borderActive: {
      border: "2px solid #d9d9d9",
      padding: "4px 10px",
      margin: "0 5px",
      backgroundColor: `#2179FE`,
      borderColor: `#2179FE`,
      borderRadius: "50px",
      color: `${RACCOLOR.WHITE}`,
    },
    stepNotStarted: {
      color: "#8E8E8E",
    },
    stepActive: {
      color: "#2179FE",
      fontFamily: "OpenSans-bold",
    },
    stepDone: {
      color: "#000000",
      fontFamily: "OpenSans-bold",
    },
    footerFixed: {
      backgroundColor: "#fff !important",
      padding: "1rem !important",
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%) !important",
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
    },
    semiBold: {
      fontFamily: "OpenSans-semibold",
    },
    bgBlue: {
      backgroundColor: "white",
    },
    popupRental: {
      width: "165px",
      position: "absolute",
      // left: '0px',
      bottom: "100%",
      border: "1px solid #2468FF",
      backgroundColor: "white",
    },
    hideRental: {
      width: "145px",
      position: "absolute",
      // left: '0px',
      bottom: "100%",
      border: "1px solid #2468FF",
      backgroundColor: "white",
    },
    racLightBlue: {
      backgroundColor: `${RACCOLOR.LAVENDER_MIST}`,
    },
    swThemeDefaultContainer: {
      flex: "0 0 auto",
      width: "100%",
      background: `${RACCOLOR.WHITE}`,
    },
    smartWizard: {
      display: "inline-block",
      listStyle: "none",
      paddingTop: "15px",
      paddingBottom: "15px",
    },

    smartWizardList: {
      textAlign: "center",
      position: "relative",
      marginRight: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
      width: "auto",
      marginLeft: "10px",
      display: "inline-block",
      "&:after": {
        borderBottom: "2px solid #cecece",
        content: '""',
        position: "absolute",
        width: "24px",
        right: "-19px",
        top: "50%",
      },
      "&:last-child": {
        "&:after": {
          border: "none",
        },
      },
    },
    formLabel: {
      marginBottom: "0.2rem",
      color: "#000",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
    },
    pb4pp: {
      paddingBottom: theme.typography.pxToRem(24),
    },
    mx1pp: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    px3pp: {
      paddingRight: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(16),
    },
    borderDone: {
      border: "2px solid #d9d9d9",
      padding: "4px 4px",
      margin: "0 5px",
      backgroundColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      borderColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      borderRadius: "50px",
      color: `${RACCOLOR.WHITE}`,
    },
    paddingTop3: {
      paddingTop: "3px",
    },
    swThemeDefault: {
      width: "100%",
      position: "relative",
    },
    popUpFont: {
      fontSize: "20px",
    },
    dropdownitemcustom: {
      color: "#2468FF",
      fontFamily: "OpenSans-bold",
      backgroundColor: "white",
      textDecoration: "none",
      cursor: "pointer",
    },
    mb1: {
      marginbottom: "0.25rem !important",
    },
    fs12: {
      fontSize: "12px",
    },
    batchClass: {
      "&:div": {
        display: "flex",
        justifyContent: "center",
      },
    },
    multiSelect: { height: "250px", width: "100%" },
    cardBody: {
      flex: "1 1 auto",
      padding: "1rem 1rem",
      paddingBottom: "10px !important",
    },
    colMd12mt3: {
      flex: "0 0 auto",
      width: "100%",
      marginTop: "1rem !important",
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "0",
      marginRight: "-0.75rem",
    },
    paidInMarginLeft: { marginLeft: "5px" },
    header: {
      width: "100%",
      fontSize: "18px",
      fontFamily: "OpenSans-bold",
      marginBottom: "15px",
    },
    collg12: {
      flex: "0 0 auto",
      width: "100%",
    },
    colxl8: {
      flex: " 0 0 auto",
      width: "66.6666666667%",
    },
    colrs7: {
      flex: "0 0 auto",
      width: "56.3333333333%",
    },
    colxl4: {
      flex: " 0 0 auto",
      width: "35.3333333333%",
    },
    colrs5: {
      flex: " 0 0 auto",
    },
    uefloatend: {
      float: "right",
    },
    upcomingeventstatus: {
      width: "7px",
      height: "7px",
      display: "inline-block",
      borderRadius: "50px",
    },
    //create event
    customMenuContainer: {
      border: "1px solid #2468FF",
    },
    popUpHide: {
      display: "none",
    },
    popUp: {
      position: "absolute",
      bottom: "100%",
      backgroundColor: "white",
      width: "200px",
      padding: "15px",
      "& li": {
        listStyle: "none",
      },
    },
    dropdowntoggle: {
      display: "inline-block",
      marginLeft: "0.255em",
      verticalAlign: "0.255em",
      content: "",
      borderTop: "0.3em solid",
      borderRight: "0.3em solid transparent",
      borderBottom: "0",
      borderLeft: "0.3em solid transparent",
    },
    // CreateStoreEventStyles

    "@global": {
      "*::-webkit-scrollbar": {
        width: "10px",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(189, 189, 196)",
        borderRadius: "20px",
        border: "3px solid rgb(206, 204, 201)",
      },
      "*::-webkit-scrollbar-track": {
        background: "rgb(245, 243, 239)",
      },
    },

    /***************************************************************/
    /**** Setting up the default MARGIN values - starts here *****/
    /***************************************************************/
    m0: {
      margin: theme.typography.pxToRem(0),
    },
    m1: {
      margin: theme.typography.pxToRem(4),
    },
    m2: {
      margin: theme.typography.pxToRem(8),
    },
    m3: {
      margin: theme.typography.pxToRem(16),
    },
    m4: {
      margin: theme.typography.pxToRem(24),
    },
    m5: {
      margin: theme.typography.pxToRem(48),
    },
    mAuto: {
      margin: "auto",
    },

    mr0: {
      marginRight: theme.typography.pxToRem(0),
    },
    mr1: {
      marginRight: theme.typography.pxToRem(4),
    },
    mr2: {
      marginRight: theme.typography.pxToRem(8),
    },
    mr3: {
      marginRight: theme.typography.pxToRem(16),
    },
    mr4: {
      marginRight: theme.typography.pxToRem(24),
    },
    mr5: {
      marginRight: theme.typography.pxToRem(48),
    },
    mrAuto: {
      marginRight: "auto",
    },

    ml0: {
      marginLeft: theme.typography.pxToRem(0),
    },
    ml1: {
      marginLeft: theme.typography.pxToRem(4),
    },
    ml2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    ml3: {
      marginLeft: theme.typography.pxToRem(16),
    },
    ml4: {
      marginLeft: theme.typography.pxToRem(24),
    },
    ml5: {
      marginLeft: theme.typography.pxToRem(48),
    },
    mlAuto: {
      marginLeft: "auto",
    },

    mt0: {
      marginTop: theme.typography.pxToRem(0),
    },
    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },
    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },
    mt3: {
      marginTop: theme.typography.pxToRem(16),
    },
    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },
    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },
    mtAuto: {
      marginTop: "auto",
    },
    me0: {
      marginRight: 0,
    },

    me1: {
      marginRight: theme.typography.pxToRem(4),
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },

    me3: {
      marginRight: theme.typography.pxToRem(16),
    },

    me4: {
      marginRight: theme.typography.pxToRem(24),
    },

    me5: {
      marginRight: theme.typography.pxToRem(48),
    },

    meAuto: {
      marginRight: "auto",
    },

    mb0: {
      marginBottom: theme.typography.pxToRem(0),
    },
    // mb1CS: {
    //     marginBottom: theme.typography.pxToRem(4),
    // },
    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    mb4: {
      marginBottom: theme.typography.pxToRem(24),
    },
    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },
    mbAuto: {
      marginBottom: "auto",
    },
    mbFooterSpacing: {
      marginBottom: theme.typography.pxToRem(98),
    },
    ms0: {
      marginLeft: 0,
    },

    ms1: {
      marginLeft: theme.typography.pxToRem(4),
    },
    ms3: {
      marginLeft: theme.typography.pxToRem(16),
    },

    ms4: {
      marginLeft: theme.typography.pxToRem(24),
    },

    ms5: {
      marginLeft: theme.typography.pxToRem(48),
    },

    msAuto: {
      marginLeft: "auto",
    },
    my0: {
      marginTop: theme.typography.pxToRem(0),
      marginBottom: theme.typography.pxToRem(0),
    },
    my1: {
      marginTop: theme.typography.pxToRem(4),
      marginBottom: theme.typography.pxToRem(4),
    },
    my2: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },
    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },
    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },
    my5: {
      marginTop: theme.typography.pxToRem(48),
      marginBottom: theme.typography.pxToRem(48),
    },
    myAuto: {
      marginTop: "auto",
      marginBottom: "auto",
    },

    mx0: {
      marginLeft: theme.typography.pxToRem(0),
      marginRight: theme.typography.pxToRem(0),
    },
    mx1: {
      marginLeft: theme.typography.pxToRem(4),
      marginRight: theme.typography.pxToRem(4),
    },
    mx2: {
      marginLeft: theme.typography.pxToRem(8),
      marginRight: theme.typography.pxToRem(8),
    },
    mx3: {
      marginLeft: theme.typography.pxToRem(16),
      marginRight: theme.typography.pxToRem(16),
    },
    mx4: {
      marginLeft: theme.typography.pxToRem(24),
      marginRight: theme.typography.pxToRem(24),
    },
    mx5: {
      marginLeft: theme.typography.pxToRem(48),
      marginRight: theme.typography.pxToRem(48),
    },
    mxAuto: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    price1: {
      paddingleft: "68%",
    },
    /***************************************************************/
    /**** Setting up the default MARGIN values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the default PADDING values - starts here *****/
    /***************************************************************/
    p0: {
      padding: "0 !important",
    },
    p1: {
      padding: theme.typography.pxToRem(4),
    },
    p2: {
      padding: theme.typography.pxToRem(8),
    },
    p3: {
      padding: theme.typography.pxToRem(16),
    },
    p4: {
      padding: theme.typography.pxToRem(24),
    },
    p5: {
      padding: theme.typography.pxToRem(48),
    },
    pAuto: {
      padding: "auto",
    },
    modalBody1: {
      marginTop: "7%",
    },

    pr0: {
      paddingRight: theme.typography.pxToRem(0),
    },
    pr1: {
      paddingRight: theme.typography.pxToRem(4),
    },
    pr2: {
      paddingRight: theme.typography.pxToRem(8),
    },
    pr3: {
      paddingRight: theme.typography.pxToRem(16),
    },
    pr4: {
      paddingRight: theme.typography.pxToRem(24),
    },
    pr5: {
      paddingRight: theme.typography.pxToRem(48),
    },
    prAuto: {
      paddingRight: "auto",
    },

    pl0: {
      paddingLeft: theme.typography.pxToRem(0),
    },
    pl1: {
      paddingLeft: theme.typography.pxToRem(4),
    },
    pl2: {
      paddingLeft: theme.typography.pxToRem(8),
    },
    pl3: {
      paddingLeft: theme.typography.pxToRem(16),
    },
    pl4: {
      paddingLeft: theme.typography.pxToRem(24),
    },
    pl5: {
      paddingLeft: theme.typography.pxToRem(48),
    },
    plAuto: {
      paddingLeft: "auto",
    },

    pt0: {
      paddingTop: theme.typography.pxToRem(0),
    },
    pt1: {
      paddingTop: theme.typography.pxToRem(4),
    },
    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },
    pt3: {
      paddingTop: theme.typography.pxToRem(16),
    },
    pt4: {
      paddingTop: theme.typography.pxToRem(24),
    },
    pt5: {
      paddingTop: theme.typography.pxToRem(48),
    },
    ptAuto: {
      paddingTop: "auto",
    },

    pb0: {
      paddingBottom: "theme.typography.pxToRem(0)",
    },
    pb1: {
      paddingBottom: theme.typography.pxToRem(4),
    },
    pb2: {
      paddingBottom: theme.typography.pxToRem(8),
    },
    pb3: {
      paddingBottom: theme.typography.pxToRem(16),
    },
    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },
    pb5: {
      paddingBottom: theme.typography.pxToRem(48),
    },
    pbAuto: {
      paddingBottom: "auto",
    },

    py0: {
      paddingTop: theme.typography.pxToRem(0),
      paddingBottom: theme.typography.pxToRem(0),
    },
    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
    },
    py2: {
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),
    },
    py3: {
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    py4: {
      paddingTop: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(24),
    },
    py5: {
      paddingTop: theme.typography.pxToRem(48),
      paddingBottom: theme.typography.pxToRem(48),
    },
    pyAuto: {
      paddingTop: "auto",
      paddingBottom: "auto",
    },

    px0: {
      paddingLeft: theme.typography.pxToRem(0),
      paddingRight: theme.typography.pxToRem(0),
    },
    px1: {
      paddingLeft: theme.typography.pxToRem(4),
      paddingRight: theme.typography.pxToRem(4),
    },
    px2: {
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
    },
    px3: {
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
    },
    px4: {
      paddingLeft: theme.typography.pxToRem(24),
      paddingRight: theme.typography.pxToRem(24),
    },
    px5: {
      paddingLeft: theme.typography.pxToRem(48),
      paddingRight: theme.typography.pxToRem(48),
    },
    pxAuto: {
      paddingLeft: "auto",
      paddingRight: "auto",
    },

    pbCardContentCustom: {
      paddingBottom: "0.5rem !important",
    },
    /***************************************************************/
    /**** Setting up the default PADDING values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the default FONT values - starts here *****/
    /***************************************************************/
    fs1: {
      fontSize: theme.typography.pxToRem(40),
    },
    fs2: {
      fontSize: theme.typography.pxToRem(32),
    },
    fs3: {
      fontSize: theme.typography.pxToRem(28),
    },
    fs4: {
      fontSize: theme.typography.pxToRem(24),
    },
    fs5: {
      fontSize: theme.typography.pxToRem(20),
    },
    fs6: {
      fontSize: theme.typography.pxToRem(16),
    },
    fs7: {
      fontSize: theme.typography.pxToRem(14),
    },
    fontSemiBold: {
      fontFamily: "OpenSans-semibold",
    },
    fontBold: {
      fontFamily: "OpenSans-bold",
    },
    font18: {
      fontSize: theme.typography.pxToRem(18),
    },
    /***************************************************************/
    /**** Setting up the default FONT values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the BACKGROUND values - starts here *****/
    /***************************************************************/
    bgPrimary: {
      backgroundColor: "#2179FE",
    },
    bgTransparent: {
      backgroundColor: "transparent",
    },
    bgLightBlue: {
      backgroundColor: "#EFF4FF",
    },
    bgLightGrey: {
      backgroundColor: "#fafafa",
    },
    bgRed: {
      backgroundColor: "#e85c5c",
    },
    bgPaleYellow: {
      backgroundColor: "#d58b00",
    },
    /***************************************************************/
    /**** Setting up the BACKGROUND values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the COLOR values - starts here *****/
    /***************************************************************/
    textGrey: {
      color: "#4A5174",
    },
    textBlack: {
      color: "#000000",
    },
    textWhite: {
      color: "#ffffff",
    },
    textBlue: {
      color: "#2179FE",
    },
    textBlue1: {
      color: "#2279fd",
    },
    textViolet: {
      color: "#2e31be",
    },
    textDisabled: {
      color: "#818181",
    },
    racBlue: {
      backgroundColor: `${RACCOLOR.NORMAL_BLUE}`,
    },
    racSkyBlue: {
      backgroundColor: "#EFF4FF",
    },
    /***************************************************************/
    /**** Setting up the COLOR values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the TEXT TREATMNET values - starts here *****/
    /***************************************************************/
    textIndent: {
      textIndent: "-9999px",
    },
    justifySpaceBetween: {
      justifyContent: "space-between",
    },
    textCenter: {
      textAlign: "center",
    },
    textRight: {
      textAlign: "right",
    },
    textLeft: {
      textAlign: "left",
    },
    /***************************************************************/
    /**** Setting up the TEXT TREATMNET values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the BORDER and RADIUS values - starts here *****/
    /***************************************************************/
    borderBottom: {
      borderBottom: "1px solid #d9d9d9",
    },
    borderLeftViolet: {
      borderLeft: "3px solid #2d31be",
    },
    borderRadius0: {
      borderRadius: "0 !important",
    },
    borderRadius5: {
      borderRadius: theme.typography.pxToRem(5),
    },
    borderRadius10: {
      borderRadius: theme.typography.pxToRem(10),
    },
    borderRadius50: {
      borderRadius: theme.typography.pxToRem(50),
    },
    borderNone: {
      border: theme.typography.pxToRem(0),
    },
    /***************************************************************/
    /***** Setting up the BORDER and RADIUS values - ends here *****/
    /***************************************************************/

    /***************************************************************/
    /**** Setting up the COMPONENT ALIGNMENT - starts here *****/
    /***************************************************************/
    componentLeft: {
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
    },
    breadcrumb: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      padding: 0,
      marginBottom: theme.typography.pxToRem(10),
      listStyle: "none",
    },
    slotBooked: {
      border: "1px solid #2179fe",
      backgroundColor: "#2179fe",
      color: "#ffffff",
      paddingRight: "11px",
      paddingBottom: "17px",
      paddingTop: "17px",
      paddingLeft: "13px",
      fontSize: "14px",
      marginBottom: "10px",
      fontFamily: "OpenSans-semibold",
    },
    agrTimeSlot: {
      width: "20%",
      display: "inline-block",
    },
    slotBusy: {
      backgroundColor: "#eaeef1",
      color: " #767676",
      paddingRight: "11px",
      paddingBottom: "17px",
      paddingTop: "17px",
      paddingLeft: "13px",
      fontSize: "14px",
      marginBottom: "10px",
      fontFamily: "OpenSans-semibold",
      border: "0px solid",
    },
    slotAvailable: {
      border: "1px solid #2179fe",
      color: "#2179fe",
      borderImage: "initial",
      backgroundColor: "hsl(0deg 0% 100%)",
      paddingRight: "11px",
      paddingBottom: "17px",
      paddingTop: "17px",
      paddingLeft: "13px",
      fontSize: "14px",
      marginBottom: "10px",
      fontFamily: "OpenSans-semibold",
    },
    Norecords: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      width: "100%",
    },
    breadcrumbItemActive: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "12px",
      color: " #8E8E8E",
      paddingLeft: "15px",
      paddingRight: 0,
    },
    racpadLinkCustomer: {
      fontFamily: "OpenSans-bold",
      fontSize: "14px",
      color: "#2179FE",
      textDecoration: "none",
    },
    breadCrumbMarg: {
      marginLeft: "20px",
    },
    breadcrumbItem: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "12px",
    },
    breadcrumbArrow: {
      "&:before": {
        content: '""',
        width: 0,
        height: 0,
        borderTop: "5px solid transparent",
        borderLeft: "7px solid #707070",
        borderBottom: "5px solid transparent",
        marginTop: 0,
        float: "left",
        paddingRight: "13px",
        color: "#6c757d",
        paddingLeft: 0,
      },
    },
    componentCenter: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    componentRight: {
      justifyContent: "flex-end",
      alignItems: "center",
      display: "flex",
    },
    componentSpaceBetween: {
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
    },
    alignCenter: {
      alignItems: "center",
    },
    dflex: {
      display: "flex",
    },
    /***************************************************************/
    /**** Setting up the COMPONENT ALIGNMENT - starts here *****/
    /***************************************************************/

    shadow0: {
      boxShadow: "none",
    },

    w100: {
      width: "100%",
    },
    w95: {
      width: "95%",
    },
    widthAuto: {
      width: "auto",
    },

    positionRelative: {
      position: "relative",
    },
    racCard: {
      borderRadius: theme.typography.pxToRem(10),
      boxShadow: "0 0 3px #d5d4d4",
    },

    underlinetxtDecoration: {
      textDecoration: "underline !important",
    },
    /**** Need to revisit the CSS below ****/
    denominationBtn: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(24),
      paddingRight: theme.typography.pxToRem(24),
      border: "1px solid #2468ff",
      fontFamily: "Opensans-bold",
      minWidth: "100px",
    },
    denominationTextBox: {
      width: "115px",
    },
    fixedFooter: {
      position: "fixed",
      bottom: 0,
      left: 0,
      display: "flex",
      justifyContent: "space-between",
      padding: theme.typography.pxToRem(16),
      backgroundColor: "#ffffff",
      boxShadow: "0 0 3px #d9d9d9",
      marginTop: "1%",
    },
    filterPositionAlign: {
      marginBottom: "20% !important",
      marginLeft: "70% !important",
    },

    racGlobalSearchBtn: {
      cursor: "pointer",
      width: "25px",
      float: "left",
      paddingTop: "0.2rem",
    },

    customPageSize: {
      width: "400px",
    },
    borderRadiusPosition: {
      "& div": {
        "& div": {
          borderBottomLeftRadius: "18px !important",
          borderBottomRightRadius: "18px !important",
          borderTopLeftRadius: "18px !important",
          borderTopRightRadius: "0px !important",
        },
      },
    },
    //lokesh

    // formLabel: {
    //     marginBottom: "0.2rem",
    //     color: "#111111",
    //     fontFamily: "OpenSans-semibold",
    //     fontSize: theme.typography.pxToRem(14),
    // },
    modalTitle: {
      marginBottom: "0",
      lineHeight: "1.5",
    },
    modalBody: {
      position: "relative",
      flex: "1 1 auto",
      padding: "1rem",
      // marginTop: '34%'
    },
    racCol12: {
      flex: "0 0 auto",
      width: "100%",
    },
    textRed: {
      color: `${RACCOLOR.MVS_RED}`,
    },
    tabPane: {
      width: "98.5%",
    },
    // row: {
    //     display: "flex",
    //     flexWrap: "wrap",
    //     marginTop: theme.typography.pxToRem(14),
    //     marginRight: theme.typography.pxToRem(14),
    //     marginLeft: theme.typography.pxToRem(14),
    // },
    cardCE: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
      width: "100%",
    },
    paddingbadge: {
      paddingLeft: "5px",
    },
    label: {
      fontFamily: "OpenSans-semibold",
    },
    problemtext: {
      width: "100%",
      paddingBottom: "9% ",
      marginTop: "10px",
      border: "1px solid #ced4da",
      borderTopLeftRadius: "0.25rem",
      borderBottomLeftRadius: "0.25rem",
      borderTopRightRadius: "0.25rem",
      borderBottomRightRadius: "0.25rem",
    },
    dollartext: {
      width: "70%",
      "& input": {
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
        padding: "7px",
        textAlign: "right",
      },
    },
    amounttext: {
      padding: "7px 14px",
    },
    racCol6: {
      flex: "0 0 auto",

      width: "50%",
    },

    racCol10: {
      flex: "0 0 auto",

      width: "83.3333333333%",
    },

    racCol2: {
      flex: "0 0 auto",

      width: "16.666666666%",
    },

    racCol8: {
      flex: "0 0 auto",

      width: "66.6666666667%",
    },
    racCol4: {
      flex: "0 0 auto",
      width: "33.3333333333%",
    },
    floatLeft: {
      float: "left",
    },
    colGap: {
      marginLeft: "3px",
    },
    formLabelValue: {
      fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      color: "#4A5174",
    },
    w50: {
      width: "50%",
    },
    w25: {
      width: "25%",
    },
    w75: {
      width: "75%",
    },
    colHeight: {
      marginTop: "3px",
    },
    textArea: {
      display: "block",
      width: "100%",
      padding: "0.375rem 0.75rem",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#212529",
      backgroundColor: `${RACCOLOR.WHITE}`,
      border: "1px solid #ced4da",
      appearance: "none",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: "0.25rem",
      borderBottomRightRadius: "0.25rem",
    },
    ps4: {
      paddingLeft: theme.typography.pxToRem(24),
    },
    floatRight: {
      float: "right",
    },
    racpadSubheader: {
      fontSize: "18px",
      fontFamily: "OpenSans-bold",
    },
    racpadLink: {
      color: `${RACCOLOR.DEEP_SKY_BLUE}`,
      fontSize: theme.typography.pxToRem(14),
      textDecoration: "none",
      cursor: "pointer",
      fontFamily: "OpenSans-bold",
    },
    flexFill: {
      flex: "1 1 auto",
    },
    fixedBottomCSE: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.typography.pxToRem(16),
      backgroundColor: "#ffffff",
      boxShadow: "0 0 3px #d9d9d9",
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
    },
    fixedBottom2: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      padding: "1rem",
    },
    barcodeheight: {
      "& svg": {
        width: "260px !important",
      },
    },
    bgWhite: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    shadow: {
      boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
    },
    loaderStyle: {
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      textAlign: "center",
    },
    racLoaderPage: {
      textAlign: "center",
      marginTop: "250px",
      fontSize: "16px",
      marginLeft: "30px",
      marginRight: "30px",
      outline: "none",
    },
    hide: {
      display: "none",
    },
    hiddenRow: {
      backgroundColor: "white",
    },
    popUpBtnWidth: {
      width: "22%",
    },
    RACPOPMsg: {
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(17),
      letterSpacing: 0,
    },
    breakPackageModalWidth: {
      "& div": {
        "& div": {
          maxWidth: "450px !important",
          maxHeight: "500px !important",
        },
      },
    },
    GridLoader: {
      textAlign: "center",
      marginBottom: theme.typography.pxToRem(20),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      margin: "30px 0px",
    },
    racpadBadge: {
      borderRadius: "20px",
    },
    bgInfo: {
      backgroundColor: "#0dcaf0",
    },
    bgWarning: {
      backgroundColor: "#ffc107",
    },
    bgAsh: {
      backgroundColor: "#c8d1d3",
    },
    bgSuccess: {
      backgroundColor: "#198754",
    },
    badge: {
      display: "inline-block",
      marginTop: "10px",
      padding: "5px 10px",
      fontSize: "0.75em",
      fontWeight: 700,
      lineHeight: 1,
      color: "#fff",
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "baseline",
      borderRadius: "20px",
    },
    racpadContainer: {
      marginTop: "63px",
    },
    racpadTab: {
      display: "flex",
    },
    navLinkItem: {
      borderBottom: "transparent",
      fontSize: "24px",
      fontFamily: "OpenSans-semibold",
      whiteSpace: "nowrap",
      cursor: "pointer",
    },
    navLinkActiveHeader: {
      color: "black",
      borderBottom: "2px solid #e3dfdf",
    },
    modalWidth: {
      "& div": {
        "& div": {
          maxWidth: "330px !important",
          maxHeight: "390px !important",
        },
      },
    },
    saveModalWidth: {
      "& div": {
        "& div": {
          maxWidth: "380px !important",
        },
      },
    },
    chargeOffModalWidth: {
      "& div": {
        "& div": {
          maxWidth: "380px !important",
          maxHeight: "210px !important",
        },
      },
    },
    chargeOffModalWidth1: {
      "& div": {
        "& div": {
          maxWidth: "340px !important",
          maxHeight: "190px !important",
        },
      },
    },
    chargeOffModalWidth2: {
      "& div": {
        "& div": {
          maxWidth: "400px !important",
          maxHeight: "180px !important",
        },
      },
    },
    px6: {
      paddingRight: theme.typography.pxToRem(45),
      paddingLeft: theme.typography.pxToRem(45),
    },
    agrPrintDoc: {
      color: "#2179fe",
    },
    ahActive: {
      borderColor: "#5AE286 !important",
    },

    ahInactive: {
      borderColor: "#BBC1CE !important",
    },

    ahDue: {
      borderColor: "#FD6A63 !important",
    },
    ahPending: {
      borderColor: "yellow !important",
    },
    racpadAgrLink: {
      borderLeft: "3px solid transparent",
      paddingLeft: "10px",
      borderRadius: "2px",
    },
    title: {
      color: "#212529",
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(10),
    },
    subTitle: {
      color: "#212529",
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(14),
      marginBottom: theme.typography.pxToRem(10),
    },
    headerMainDiv: {
      paddingLeft: "7px",
      paddingRight: "8px",
    },
    headerMainCard: {
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
    },
    pb12: {
      paddingBottom: "12px",
    },
    mainInvCard: {
      marginLeft: "6.5px",
      marginRight: "8px",
      borderBottomRightRadius: "0px",
      borderBottomLeftRadius: "0px",
    },
    mainInvCardContent: {
      paddingTop: "4px !important",
      paddingBottom: "14px !important",
    },
    textAreaStyle: {
      marginTop: "4px",
      borderRadius: "7px",
      fontSize: "1pc",
      padding: "12px",
      fontFamily: "initial",
    },
    pt9: {
      paddingTop: "9px",
    },
    pt14: {
      paddingTop: "14px",
    },
    pt16: {
      paddingTop: "16px",
    },
    pb9: {
      paddingBottom: "9px",
    },
    pb10: {
      paddingBottom: "10px",
    },
    ml6: {
      marginLeft: "6px",
    },
    mt7: {
      marginTop: "7px",
    },
    mt20: {
      marginTop: "20px",
    },
    mt57: {
      marginTop: "57px",
    },
    mt110: {
      marginTop: "110px",
    },
    mr45: {
      marginRight: "45px",
    },
    mb10: {
      marginBottom: "10px",
    },
    mb12: {
      marginBottom: "12px",
    },
    mb20: {
      marginBottom: "20px",
    },
    popUpMainDiv: {
      paddingTop: "0px",
      paddingBottom: "0px",
      marginTop: "-14px",
    },
    mtm13: {
      marginTop: "-13px",
    },
    mtm28: {
      marginTop: "-28px",
    },
    mtm20: {
      marginTop: "-20px",
    },
    mbm10: {
      marginBottom: "-10px",
    },
    //
    ml45: {
      marginLeft: "45%",
    },
    mtm4: {
      marginTop: "-4px",
    },
    mtm5: {
      marginTop: "-5px",
    },
    mtm9: {
      marginTop: "-9px",
    },
    mb100: {
      marginBottom: "100px",
    },
    fiveColumns: {
      ["@media (min-width:1366px)"]: {
        maxWidth: "20%!important",
        flexBasis: "20%",
      },
    },
    fourColumns: {
      ["@media (min-width:1366px)"]: {
        maxWidth: "25%!important",
        flexBasis: "25%",
      },
    },
    borderRadius4px: {
      borderRadius: "4px",
    },
    textMildGrey: {
      color: "#6c757d",
    },
    statusBadge: {
      padding: "1px 8px",
      marginLeft: "8px",
      color: "white",
      borderRadius: "10px",
      fontSize: "12px",
    },
    statusGreen: {
      backgroundColor: "#03c03c",
    },
    statusWarning: {
      backgroundColor: "#d68b00",
    },
    statusInfo: {
      backgroundColor: "#0bbab6",
    },
    width10: {
      width: "50px",
    },
    headerWidth: {
      marginLeft: "-15px",
      marginRight: "-12px",
    },
    //added by krishnaja for actionbutton
    actionBtn: {
      color: "#2179FE",
      borderRadius: theme.typography.pxToRem(5),
      backgroundColor: "rgb(239,244,255)",
      fontSize: theme.typography.pxToRem(12),
    },
    // added by charitra
    cursorDefault: {
      cursor: "default",
    },
    masterLoader: {
      position: "fixed",
      backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      opacity: 0.6,
      zIndex: 1050,
      textAlign: "center",
      margin: 0,
    },
    Loader: {
      display: "block",
      position: "fixed",
      zIndex: 1051,
      top: "50%",
      right: "50%",
    },
    //added by krishnaja for priceTagPopUP
    marginLeftRight40: {
      marginLeft: "40%",
      marginRight: "40%",
    },
    testCss: {
      borderRadius: theme.typography.pxToRem(4),
      width: "150px",
      border: "0.5px solid grey",
      fontFamily: "OpenSans-semibold",
      padding: theme.typography.pxToRem(6),
      textAlignLast: "right",
      borderColor: "#c8d1d3",
      "&:focus": {
        borderColor: "0.5px solid red",
      },
    },
    mr6: {
      marginRight: theme.typography.pxToRem(50),
    },
    inputBox: {
      borderRadius: theme.typography.pxToRem(16),
      width: "180px",
      padding: "0.375rem 0.75rem",

      "& input": {
        height: "22px",
        fontSize: "16px",
        fontWeight: 400,
      },
    },
    textRightAlign: {
      textAlignLast: "right",
    },
    btnWidth: {
      width: "27%",
    },
    width40: {
      width: "40%",
    },
    priceTagPageLoadModalWidth: {
      "& div": {
        "& div": {
          maxWidth: "600px !important",
          maxHeight: "190px !important",
        },
      },
    },
    priceTagPrintModalWidth: {
      "& div": {
        "& div": {
          maxWidth: "350px !important",
          maxHeight: "500px !important",
        },
      },
    },
    priceExceptionModalWidth: {
      "& div": {
        "& div": {
          maxWidth: "600px !important",
          maxHeight: "800px !important",
        },
      },
    },
    formControl: {
      display: "block",
      width: "100%",
      padding: "0.375rem 0.75rem",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#212529",
      backgroundColor: `${RACCOLOR.WHITE}`,
      border: "1px solid #ced4da",
      appearance: "none",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: "0.25rem",
      borderBottomRightRadius: "0.25rem",
      textAlign: "right",
    },

    racLoader: {
      textAlign: "center",
      marginTop: "328px",
      fontSize: "16px",
      marginLeft: "30px",
      marginRight: "30px",
      outline: "none",
    },
    listTabPadding: {
      padding: "15px 20px 12px 20px !important",
    },
    headerCardPadding: {
      padding: "0px 0px 0px 6px !important",
      marginLeft: "14px !important",
    },
    listPadding: {
      padding: "6px 0px 0px 0px !important",
    },
    w80: {
      width: "80%",
    },
    agreementContainerSpacing: {
      paddingLeft: "10px",
      paddingRight: "10px",
      marginBottom: "120px",
    },
    // added by kalai

    justifyContentCenter: {
      alignItems: "center",
      marginTop: "10%",
      justifyContent: "center",
    },
    dynamicCssForSelect: {
      "& div": {
        borderBottomLeftRadius: "18px !important",
        borderBottomRightRadius: "18px !important",
        borderTopLeftRadius: "18px !important",
        borderTopRightRadius: "18px !important",
      },
    },

    //for inventoryTrasfer Grid added by Krishnaja
    bgTransparentRed: {
      backgroundColor: "rgba(255,0,0,0.4)",
      color: "#ffffff",
      "&:hover": {
        cursor: "default",
        backgroundColor: "rgba(255,0,0,0.4)",
      },
    },
    onBtnHover: {
      "&:hover": {
        backgroundColor: "#e85c5c",
      },
    },
    selectBox: {
      "& div": {
        padding: "10px 9px",
      },
    },
    fs8: {
      fontSize: theme.typography.pxToRem(13),
    },
    makeBold: {
      fontWeight: "bolder",
    },
    px7: {
      paddingLeft: theme.typography.pxToRem(35),
      paddingRight: theme.typography.pxToRem(35),
    },
    SelectLanguageModalWidthmax: {
      "& div": {
        "& div": {
          maxWidth: "700px !important",
          maxHeight: "800px !important",
        },
      },
    },
    SelectLanguageModalWidth: {
      "& div": {
        "& div": {
          "& h5": {
            textAlign: "center !important",
          },
        },
      },
    },

    px8: {
      paddingLeft: theme.typography.pxToRem(30),
      paddingRight: theme.typography.pxToRem(30),
    },
    margincard: {
      margin: "18px 10px",
      fontSize: theme.typography.pxToRem(14),
    },
    bluecards: {
      boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
      width: "100%",
    },
    priceTagLabelColour: {
      fontFamily: "OpenSans-bold",
      color: `${RACCOLOR.GRAY}`,
      fontSize: "14px",
    },
    margincardsize: {
      marginTop: "10px",
    },
    mxe: {
      marginLeft: theme.typography.pxToRem(4),

      marginRight: theme.typography.pxToRem(36),
    },
    formCheck: {
      minHeight: "1.5rem",
      marginBottom: "0.125rem",
      display: "inline-block",
      marginRight: "1rem",
      paddingLeft: 0,
    },
    spacerMB5: {
      marginBottom: theme.typography.pxToRem(24),
    },
    spacerMT8: {
      marginTop: theme.typography.pxToRem(20),
    },
    font14: {
      fontSize: theme.typography.pxToRem(14),
    },
    MarginDate: {
      marginBottom: "5px",
    },
    agrListGroup: {
      display: "flex",
      flexDirection: "column",
      paddingRight: "10px",
      marginBottom: 0,
      borderRadius: "0.25rem",
    },
    deliveryEventDesc: {
      fontSize: "13px",
      fontFamily: "OpenSans-regular",
      color: "#525252",
    },
    me48: {
      marginRight: theme.typography.pxToRem(48),
    },
    circleAvailable: {
      width: "10px",
      height: "10px",
      float: "left",
      borderRadius: "20px",
      marginTop: "4px",
      marginRight: "7px",
      background: "#2468FF",
    },
    spacerMR4: {
      marginRight: theme.typography.pxToRem(12),
    },
    circleBusy: {
      width: "10px",
      height: "10px",
      float: "left",
      borderRadius: "20px",
      marginTop: "4px",
      marginRight: "7px",
      background: "#D3D4D5",
    },
    cardidle: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
      marginLeft: "6px",
      marginRight: "6px",
      padding: "0%",
    },
    commentText: {
      "& input: focus": {
        border: "1px solid #ced4da",
      },
      width: "100%",
      paddingBottom: "9% ",
      marginTop: "10px",
      border: "1px solid #ced4da",
      borderTopLeftRadius: "0.25rem",
      borderBottomLeftRadius: "0.25rem",
      borderTopRightRadius: "0.25rem",
      borderBottomRightRadius: "0.25rem",
    },
    dropdowntoggle: {
      display: "inline-block",
      marginLeft: "0.255em",
      verticalAlign: "0.255em",
      content: "",
      borderTop: "0.3em solid",
      borderRight: "0.3em solid transparent",
      borderBottom: "0",
      borderLeft: "0.3em solid transparent",
    },
    me1: {
      marginRight: theme.typography.pxToRem(4),
    },
    pr: {
      position: "relative",
    },
    businessDate: {
      float: "left",
      marginBottom: "3%",
    },
    hideRental: {
      width: "165px",
      position: "absolute",
      left: "0px",
      bottom: "100%",
      border: "1px solid #2468FF",
      backgroundColor: "white",
    },
    popupRental: {
      width: "165px",
      position: "absolute",
      left: "0px",
      bottom: "100%",
      border: "1px solid #2468FF",
      backgroundColor: "white",
    },
    printpopup: {
      width: "100%",
    },
    popupforprint: {
      padding: "3%",
      marginBottom: "25%",
    },
    anchorview: {
      marginTop: "0.1%",
    },
    dropdownitemcustom: {
      color: "#2468FF",
      fontFamily: "OpenSans-bold",
      backgroundColor: "white",
      textDecoration: "none",
      cursor: "pointer",
    },
    bgBlue: {
      backgroundColor: "white",
    },
    GridLoader: {
      textAlign: "center",
      marginBottom: theme.typography.pxToRem(20),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      margin: "30px 0px",
    },
    fixTableHead: {
      overflowY: "auto",
      maxHeight: "205px",
      "& th": {
        position: "sticky",
        top: 0,
        backgroundColor: "#ffffff",
        fontFamily: "OpenSans-semibold",
        zIndex: 1,
        padding: "0.9375rem 0.2rem",
        whiteSpace: "nowrap",
      },
      "& tbody": {
        "& tr": {
          position: "sticky",
          top: 10,
          backgroundColor: "#ffffff",
        },
        "& td": {
          fontFamily: "OpenSans-semibold",
          padding: "0.9375rem 0.2rem",
          color: "#4A5174",
        },
      },
    },
    fixTableHeight300: {
      maxHeight: "300px",
    },
    fixTableHeight400: {
      maxHeight: "400px",
    },
    title: {
      color: "#212529",
      fontFamily: "OpenSans-bold",
      fontSize: "16px",
      marginBottom: "10px",
    },
    ssnBorderRed: {
      border: "1px solid red",
      borderRadius: "6px",
      outline: "none",
    },
    racGlobalSearchBtn: {
      cursor: "pointer",
      width: "25px",
      float: "left",
      paddingTop: "0.2rem",
    },
    widgetTitle: {
      fontSize: theme.typography.pxToRem(16),
      fontFamily: "OpenSans-bold",
      color: `${RACCOLOR.WASHED_BLACK}`,
    },
    spacer: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
    },
    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
      width: "100%",
    },
    customerFloatright: {
      float: "right",
    },
    customerFloatleft: {
      float: "left",
    },
    foc: {
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "1px 1px 6px 3px #80bdff",
      },
    },
    focforLink: {
      "&:focus": {
        border: "1px solid #80bdff",
        outline: "none",
        borderRadius: "5px",
      },
    },
    focforLinkPaymentIssue: {
      outline: "none",
    },
    colRight: {
      textAlign: "right",
    },
    spacerMT5: {
      marginTop: theme.typography.pxToRem(48),
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: "none",
    },
    spacerME2: {
      marginRight: theme.typography.pxToRem(16),
    },
    fixedBottom: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      padding: "1rem",
    },
    customerRow: {
      width: "100%",
    },
    customerJustifycontentcenter: {
      justifyContent: "center",
    },

    customerToolbar: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    spacerP0: {
      padding: theme.typography.pxToRem(0),
    },
    spacerP2: {
      padding: theme.typography.pxToRem(16),
    },
    spacerMT0: {
      marginTop: theme.typography.pxToRem(0),
    },
    spacerMB3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    spacerMT3: {
      marginTop: theme.typography.pxToRem(16),
    },
    spacerMT4: {
      marginTop: theme.typography.pxToRem(24),
    },
    spacerMB1: {
      marginBottom: theme.typography.pxToRem(4),
    },
    spacerMT1: {
      marginTop: theme.typography.pxToRem(4),
    },
    spacerMR2: {
      marginRight: theme.typography.pxToRem(8),
    },
    spacerMR1: {
      marginRight: theme.typography.pxToRem(16),
      marginTop: theme.typography.pxToRem(16),
    },
    spacerPX2: {
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
    },
    spacerMB3PX1: {
      marginBottom: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(4),
      paddingRight: theme.typography.pxToRem(4),
    },
    spacerPT1: {
      paddingTop: theme.typography.pxToRem(16),
    },
    spacerPB2: {
      paddingBottom: theme.typography.pxToRem(8),
    },
    spacerMB2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    spacerMS2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },
    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },
    customerDisplayinline: {
      display: "inline-block",
    },
    customerColmd: {
      flex: "0 0 auto",
      width: "100%",
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    sideTitle: {
      fontSize: theme.typography.pxToRem(18),
      color: `${RACCOLOR.WASHED_BLACK}`,
      marginBottom: theme.typography.pxToRem(10),
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: "OpenSans-bold",
    },
    customerTablecellbgcolor: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    customerAccordionopen: {
      borderLeft: "2px solid #7bbffc",
      boxShadow: "0px -1px 2px 0px #eaeff5",
      borderTopLeftRadius: theme.typography.pxToRem(12),
      borderTopRightRadius: theme.typography.pxToRem(12),
    },
    customerHiddenrow: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    customerAccordianopen: {
      borderLeft: "5px solid #7bbffc",
      boxShadow: "0px -1px 2px 0px #eaeff5",
      borderTopLeftRadius: theme.typography.pxToRem(12),
      borderTopRightRadius: theme.typography.pxToRem(12),
    },
    customerCheckbox: {
      color: `${RACCOLOR.DEEP_SKY_BLUE}`,
    },
    customerRaclink: {
      color: `${RACCOLOR.BLUE_CRAYOLA}`,
      textDecoration: "none",
      fontFamily: "OpenSans-semibold",
      cursor: "pointer",
    },

    customerLinebreak: {
      whiteSpace: "nowrap",
    },
    customerPointercursor: {
      cursor: "pointer",
    },
    customerTextend: {
      textAlign: "right",
    },
    customerTextcenter: {
      textAlign: "center",
    },
    customerRacpopup: {
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: "0px",
    },
    spacerMS1: {
      marginLeft: theme.typography.pxToRem(4),
    },
    customerLineBreak: {
      whiteSpace: "nowrap",
    },
    checkBoxwidth: {
      width: theme.typography.pxToRem(10),
    },
    customerTextsubtilegrid: {
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(18),
      color: "#000",
    },
    customerNorecords: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
    },
    customerNorecordsAgr: {
      textAlign: "center",
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
    },
    customerNorecordsAlert: {
      textAlign: "center",
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      marginRight: "10px !important",
    },
    alertLoader: {
      width: "30px !important",
      height: "30px !important",
      marginTop: "10px",
    },
    formLabel: {
      marginBottom: "2px",
      float: "left",
      color: "#111111",
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
    },
    fixedBottomAddCo: {
      position: "sticky",
      bottom: "-19px",
      right: "0px",
      backgroundColor: "white",
      padding: "14px 0px",
    },

    formLabelAddCo: {
      marginBottom: "0.2rem",
      color: "#111111",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
    },

    mandatoryfield: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
    },
    demooooo: {
      color: "yellow",
    },
    RACPOPMsg: {
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },
    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },
    ps2: {
      paddingLeft: theme.typography.pxToRem(8),
    },
    spacerMT2: {
      marginTop: theme.typography.pxToRem(16),
    },
    semiBold: {
      fontFamily: "OpenSans-semibold",
    },
    formCheck: {
      minHeight: "1.5rem",
      marginBottom: "0.125rem",
      display: "inline-block",
      marginRight: "1rem",
      paddingLeft: 0,
    },
    textRight: {
      textAlign: "right",
    },
    textCenterSSN: {
      "& input": {
        textAlign: "center",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    w100: {
      width: "100%",
    },
    floatLeft: {
      float: "left",
    },
    floatRight: {
      float: "right",
    },
    justifyContentCenter: {
      justifyContent: "center",
    },
    bold: {
      fontFamily: "OpenSans-bold",
    },
    font16: {
      fontSize: "16px",
    },
    customerTable: {
      "& tr": {
        backgroundColor: "transparent",
      },
    },
    paymentIssue: {
      "& td": {
        backgroundColor: "#ffefef",
      },
      "& td:first-child": {
        borderTopLeftRadius: "7px",
        borderBottomLeftRadius: "7px",
      },
      "& td:last-child": {
        borderTopRightRadius: "7px",
        borderBottomRightRadius: "7px",
      },
    },
    racpadAgrLink: {
      borderLeft: "3px solid transparent",
      paddingLeft: "10px",
      borderRadius: "2px",
    },
    racpadPaymentFails: {
      position: "relative",
      "&:before": {
        content: '""',
        height: "20px",
        width: "3px",
        background: "#fd6a63",
        position: "absolute",
        left: 0,
        top: 0,
        borderTopRightRadius: "2px",
        borderBottomRightRadius: "2px",
      },
    },
    racpadPaymentInActive: {
      borderColor: "#b1adac",
    },
    racpadPaymentSuccess: {
      position: "relative",
      "&:before": {
        content: '""',
        height: "20px",
        width: "3px",
        background: "#56e0d8",
        position: "absolute",
        left: 0,
        top: 0,
        borderTopRightRadius: "2px",
        borderBottomRightRadius: "2px",
      },
    },
    racstrapTablecellBgColor: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    racpadLink: {
      color: "#2468ff",
      fontSize: "14px",
      textDecoration: "none",
      cursor: "pointer",
    },
    racpadClubLink: {
      color: "#000000",
      fontSize: "14px",
      textDecoration: "none",
    },
    lineBreak: {
      whiteSpace: "nowrap",
    },
    agreementRow: {
      borderBottom: "2px solid white",
    },
    RACLoaderPage: {
      textAlign: "center",
      marginTop: "250px",
      fontSize: "16px",
      marginLeft: "30px",
      marginRight: "30px",
      outline: "none",
    },
    racFixedFooterMargin: {
      marginBottom: "120px",
    },
    racGrid: {
      "& th": {
        fontFamily: "OpenSans-semibold",
        fontSize: "14px",
        color: "#000",
      },
      "& td": {
        fontFamily: "OpenSans-semibold",
        fontSize: "14px",
        color: "#4A5174",
      },
    },
    txtSubtileGrid: {
      fontFamily: "OpenSans-bold",
      fontSize: "18px",
      color: "#000000",
    },
    badgeContainer: {
      padding: "1px 13px",
      borderRadius: "10px",
      backgroundColor: "#ddf8ed",
      color: "#10523e",
      marginLeft: " 5px",
    },
    px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
    },
    spacerP1: {
      padding: ".375rem .75rem",
    },
    mb100: {
      marginBottom: "120px",
    },
    loaderStyle: {
      border: "none",
      backgroundColor: "transparent",
      // userFocus: 'none',
      // MozUserFocus: 'none',
      outline: "none",
    },
    ssnpadding: {
      paddingTop: "0px",
    },
    datePicker: {
      paddingTop: "9px !important",
      marginBottom: "0px !important",
      "& div": {
        "& div": {
          "& fieldset": {
            borderColor: "#c4c4c4 !important",
          },

          "& input": {
            padding: "6px",

            paddingLeft: "12px",
          },
        },
      },
    },
    datePickerAddCo: {
      marginBottom: "0px !important",
      marginTop: "4px !important",
    },
    datePickerMB: {
      marginBottom: "1px !important",
      marginTop: "4px !important",
    },
    titleColor: {
      color: "gray",
    },
    infoTextStyle: {
      color: "gray",
      fontSize: theme.typography.pxToRem(13),
      textAlign: "center",
    },
    cardHeight: {
      height: theme.typography.pxToRem(271),
    },
    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },
    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },
    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },
    tablebggreen: {
      backgroundColor: "#F0FDF5",
    },
    masterLoader: {
      position: "fixed",
      backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      opacity: 0.6,
      zIndex: 1050,
      textAlign: "center",
      margin: 0,
    },
    Loader: {
      display: "block",
      position: "fixed",
      zIndex: 1051,
      top: "50%",
      right: "50%",
    },
    marginbottom2: {
      marginBottom: "6px!important",
    },
    pb0: {
      paddingBottom: "!important",
    },
    dispInlineFlex: {
      display: "inline-flex",
    },
    txtStart: {
      textAlign: "start",
    },
    ssnPaddingCocust: {
      padding: "6px 8px 8px 8px !important",
    },
    ssnTextField: {
      "& input": {
        ["@media (min-width:768px) and (max-width:1280px)"]: {
          padding: "7px 9px!important",
        },
      },
    },
    agreementGrid: {
      width: "94%",
      margin: "0 auto !important",
      borderCollapse: "separate",
      borderSpacing: "0 5px !important",
    },
    accordianWidth: {
      width: "42px",
    },
    cusomerInfo: {
      borderLeft: "7px solid #7bbffc",
      boxShadow: "0 1px 25px 0 rgb(0 0 0 / 7%)",
    },
    agreementGridInnerRow: {
      marginLeft: "-1.5px",
      boxShadow: "4px 4px 4px #eaeaea",
      borderLeft: "5px solid #7bbffc",
      paddingBottom: "2rem",
      marginBottom: "2rem",
    },
    textAlign: {
      textAlign: "left",
    },
    borderRadiusZero: {
      borderRadius: "0px !important",
    },
    gridBorderBottom: {
      borderBottom: "1px solid #eaeef5",
    },
    RACPOPMsgforCC: {
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    clrntnstyle: {
      marginTop: "17px!important",
      padding: "6px 20px !important",
    },
    pl40: {
      paddingLeft: "32px!important",
      color: "#212529",
      fontSize: "16px",
      fontFamily: "OpenSans-bold",
      marginBottom: "10px",
    },
    pl28: {
      paddingLeft: "28px!important",
    },
  }));
  const classes = useClasses();

  return classes;
};
