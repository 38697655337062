/* eslint-disable prettier/prettier */
import { sanitizeURL } from "../utils/utils";

export interface ApiUrls {
  readonly container: string;
  readonly micro: string;
  readonly customers?: string;
  readonly storemgmt: string;
}

export interface AppConfigItem {
  readonly apiUrls: ApiUrls;
  readonly microUrl: string;
  readonly auth: {
    readonly region: string;
    readonly userPoolId: string;
    readonly userPoolWebClientId: string;
    readonly oauth: {
      readonly domain: string;
      readonly scope?: string[];
      readonly redirectSignIn: string;
      readonly redirectSignOut: string;
      readonly responseType: string;
      readonly urlOpener: (url: string) => void;
    };
  };
}

const microUrl = {
  localhost: "http://localhost:3006",
  local: "https://local-reporting-racpad.rentacenter.com",
  dev: "https://dev-reporting-racpad.rentacenter.com",
  qa: "https://qa-reporting-racpad.rentacenter.com",
  uat: "https://uat-reporting-racpad.rentacenter.com",
  prod: "https://racpad-reporting.rentacenter.com",
};

export interface AppConfig extends AppConfigItem {
  readonly appName: string;
}

export const urlOpener = (url: string): void => {
  const urlToOpen = sanitizeURL(url);

  if (urlToOpen) {
    window.open(urlToOpen, "_self");
  }
};

export const localhost: AppConfigItem = {
  apiUrls: {
    container: "https://local-racpad.rentacenter.com/api",
    micro: "https://local-reporting-racpad.rentacenter.com/api",
    customers: "https://local-customers-racpad.rentacenter.com/api",
    storemgmt: "https://local-storemgmt-racpad.rentacenter.com/api",
  },
  microUrl: microUrl.localhost,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_FPWut7aB",
    userPoolWebClientId: "608s8dso4aecka8omjpukssq3n",
    oauth: {
      domain: "auth-local-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.localhost,
      redirectSignOut: microUrl.localhost,
      responseType: "token",
      urlOpener,
    },
  },
};

export const localhostIE11: AppConfigItem = { ...localhost };

export const local: AppConfigItem = {
  apiUrls: {
    container: "https://local-racpad.rentacenter.com/api",
    micro: "https://local-reporting-racpad.rentacenter.com/api",
    customers: "https://local-customers-racpad.rentacenter.com/api",
    storemgmt: "https://local-storemgmt-racpad.rentacenter.com/api",
  },
  microUrl: microUrl.local,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_FPWut7aB",
    userPoolWebClientId: "608s8dso4aecka8omjpukssq3n",
    oauth: {
      domain: "auth-local-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.local,
      redirectSignOut: microUrl.local,
      responseType: "token",
      urlOpener,
    },
  },
};

export const dev: AppConfigItem = {
  apiUrls: {
    container: "https://dev-racpad.rentacenter.com/api",
    micro: "https://dev-reporting-racpad.rentacenter.com/api",
    customers: "https://dev-customers-racpad.rentacenter.com/api",
    storemgmt: "https://dev-storemgmt-racpad.rentacenter.com/api",
  },
  microUrl: microUrl.dev,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_8MbOyEYG6",
    userPoolWebClientId: "47r2ihk9fehcpn9t64thdbu2tl",
    oauth: {
      domain: "auth-qa-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.dev,
      redirectSignOut: microUrl.dev,
      responseType: "token",
      urlOpener,
    },
  },
};

export const qa: AppConfigItem = {
  apiUrls: {
    container: "https://qa-racpad.rentacenter.com/api",
    micro: "https://qa-reporting-racpad.rentacenter.com/api",
    customers: "https://qa-customers-racpad.rentacenter.com/api",
    storemgmt: "https://qa-storemgmt-racpad.rentacenter.com/api",
  },
  microUrl: microUrl.qa,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_8MbOyEYG6",
    userPoolWebClientId: "47r2ihk9fehcpn9t64thdbu2tl",
    oauth: {
      domain: "auth-qa-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.qa,
      redirectSignOut: microUrl.qa,
      responseType: "token",
      urlOpener,
    },
  },
};

export const uat: AppConfigItem = {
  apiUrls: {
    container: "https://uat-racpad.rentacenter.com/api",
    micro: "https://uat-reporting-racpad.rentacenter.com/api",
    customers: "https://uat-customers-racpad.rentacenter.com/api",
    storemgmt: "https://uat-storemgmt-racpad.rentacenter.com/api",
  },
  microUrl: microUrl.uat,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_FBmCthpda",
    userPoolWebClientId: "1d8vgr67fr7gli9abpv9j1cb3n",
    oauth: {
      domain: "auth-uat-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.uat,
      redirectSignOut: microUrl.uat,
      responseType: "token",
      urlOpener,
    },
  },
};

export const prod: AppConfigItem = {
  apiUrls: {
    container: "https://racpad.rentacenter.com/api",
    micro: "https://racpad-reporting.rentacenter.com/api",
    customers: "https://racpad-customers.rentacenter.com/api",
    storemgmt: "https://storemgmt-racpad.rentacenter.com/api",
  },
  microUrl: microUrl.prod,
  auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_so5RR57Of",
    userPoolWebClientId: "50nk6ibf5lru47hesk4j0cfuu4",
    oauth: {
      domain: "auth-racpad.auth.us-east-1.amazoncognito.com",
      redirectSignIn: microUrl.prod,
      redirectSignOut: microUrl.prod,
      responseType: "token",
      urlOpener,
    },
  },
};

export const artifact: AppConfigItem = {
  apiUrls: {
    container: "_apiUrls.container",
    micro: "_apiUrls.micro",
    storemgmt: "_apiUrls.storemgmt",
  },
  microUrl: "_microUrl",
  auth: {
    region: "_auth.region",
    userPoolId: "_auth.userPoolId",
    userPoolWebClientId: "_auth.userPoolWebClientId",
    oauth: {
      domain: "_auth.oauth.domain",
      redirectSignIn: "_auth.oauth.redirectSignIn",
      redirectSignOut: "_auth.oauth.redirectSignOut",
      responseType: "_auth.oauth.responseType",
      urlOpener,
    },
  },
};

let appConfigItem: AppConfigItem;

switch (process.env.REACT_APP_STAGE) {
  case "localhost":
    appConfigItem = localhost;
    break;
  case "localhost-ie11":
    appConfigItem = localhostIE11;
    break;
  case "local":
    appConfigItem = local;
    break;
  case "dev":
    appConfigItem = dev;
    break;
  case "qa":
    appConfigItem = qa;
    break;
  case "uat":
    appConfigItem = uat;
    break;
  case "prod":
    appConfigItem = prod;
    break;
  case "artifact":
    appConfigItem = artifact;
    break;
  default:
    appConfigItem = localhost;
    break;
}

export const appConfig: AppConfig = {
  appName: "Reporting",
  ...appConfigItem,
};
